import React from "react";
import InvoiceListWithSearch from "./InvoiceListWithSearch";
import InvoiceStatusRibbon from "./InvoiceStatusRibbon";
import ClarificationRibbon from "./ClarificationRibbons";
import InvoiceSummary from "./InvoiceSummary";
import InvoiceImageList from "./InvoiceImageList";
import GMClarifications from "./GMClarifications";
import TrackRequest from "./TrackRequest";
import DetailedStatus from "./DetailedStatus";
import Box from "../Box";
import ResponseAction from "./ResponseActions";
import { twMerge } from "../../utils";
import Typography from "../Typography";
import Checkbox from "../Checkbox";
import EmptyClaimDataState from "./EmptyClaimDataState";
import InvoiceBreakUp from "./InvoiceBreakup";
import InputField from "../InputField";
import ResubmissionBanner from "./ResubmissionBanner";

const styles = {
  expenseDetailsContainer: "w-3/4 relative lg:w-full",
  expenseEmptyContainer: "w-3/4 flex justify-center lg:w-full",
  expenseDetailsContainerScroll: { height: `calc(100vh - 18rem)` },
  batchSelectFullHeight: { height: `auto` },
  batchSelectOverlay:
    "bg-white/90 z-10 h-full overflow-hidden absolute inset-0 w-full flex items-center justify-center",
};

const ClaimDetailsBody = ({
  onCommentChange,
  onApprove,
  onClarify,
  onDecline,
  onSearch,
  showInvoiceList,
  activeClaim,
  communication,
  searchText,
  onBatchSelectToggle,
  isBatchSelected,
  onAcknowledgementChange,
  onApproveAll,
  noPendingClaims,
  claimsList,
  onClaimClick,
  onApprovedAmountChange,
  activeStatus,
  onViewImg,
}) => {
  return (
    <Box className="flex flex-row lg:flex-col">
      <InvoiceListWithSearch
        activeInvoiceNumber={activeClaim?.invoiceNo}
        searchValue={searchText}
        onSearch={onSearch}
        invoiceList={claimsList}
        showInvoiceList={showInvoiceList}
        onBatchSelectToggle={onBatchSelectToggle}
        isBatchSelected={isBatchSelected}
        onApproveAll={onApproveAll}
        onClaimClick={(val) => onClaimClick(val)}
        activeStatus={activeStatus}
      />

      {noPendingClaims ? (
        <Box className={styles.expenseEmptyContainer}>
          <EmptyClaimDataState />
        </Box>
      ) : (
        <Box
          className={twMerge(
            styles.expenseDetailsContainer,
            isBatchSelected ? "overflow-y-hidden " : "overflow-y-auto"
          )}
          style={styles.expenseDetailsContainerScroll}
        >
          <InvoiceStatusRibbon
            status={activeClaim?.status}
            isClarified={activeClaim?.isClarified}
          />
          {communication.length > 0 && (
            <ClarificationRibbon communications={communication} />
          )}
          <InvoiceSummary activeClaim={activeClaim} />
          <InvoiceBreakUp expenseList={activeClaim?.expenseList} />
          {activeClaim?.resubmissionComment && (
            <ResubmissionBanner comment={activeClaim?.resubmissionComment} />
          )}
          <InvoiceImageList
            imageList={
              activeClaim?.invoiceUrl?.length > 0 && activeClaim?.invoiceUrl
            }
            onViewImg={onViewImg}
          />
          <Box className="px-6 mt-4">
            <InputField
              className={"bg-slate-200"}
              label="Approved Amount"
              required
              value={activeClaim.approvedAmount || 0}
              onChange={onApprovedAmountChange}
            />
          </Box>
          <GMClarifications
            comments={activeClaim.comments}
            data={activeClaim?.nextApprover || []}
            // showButton={activeClaim?.showBut}
            showButton={true}
            onCommentChange={onCommentChange}
          />
          <Box className="flex flex-col px-6 py-2 gap-2">
            <Checkbox
              selected={activeClaim.isPhysicalCopyReceived}
              onToggle={(val) =>
                onAcknowledgementChange(val, "isPhysicalCopyReceived")
              }
              rightLabel="By checking this box, I acknowledge receipt of the original invoices/documents"
              className="text-primary text-sm text-left"
            />
            <Checkbox
              selected={activeClaim.isAllAmountVerified}
              onToggle={(val) =>
                onAcknowledgementChange(val, "isAllAmountVerified")
              }
              rightLabel="By checking this box, I confirm that I have verified the statement amount, invoice amount, and claim amount, ensuring they match accurately"
              className="text-primary text-sm text-left"
            />
          </Box>
          {/* {activeClaim?.showBut && ( */}
          <ResponseAction
            communication={communication}
            comments={activeClaim.comments}
            onApprove={onApprove}
            onClarify={onClarify}
            onDecline={onDecline}
            isPhysicalCopyReceived={activeClaim.isPhysicalCopyReceived}
            isAllAmountVerified={activeClaim.isAllAmountVerified}
            status={activeClaim?.status}
            claimStatus={activeClaim?.status}
            nextApproverRole={
              activeClaim?.nextApprover?.filter(
                (approver) => approver.status === "inProgress"
              )?.[0]?.approver
            }
          />
          {/* )} */}
          <TrackRequest data={activeClaim?.nextApprover || []} />
          <DetailedStatus data={activeClaim?.nextApprover || []} />
          {isBatchSelected && (
            <Box
              className={styles.batchSelectOverlay}
              style={
                isBatchSelected
                  ? styles.batchSelectFullHeight
                  : styles.expenseDetailsContainerScroll
              }
            >
              <Typography className="text-3xl font-bold text-font-primary">
                All Claims Selected
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ClaimDetailsBody;

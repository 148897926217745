import Box from "../Box";
import Typography from "../Typography";

const ResubmissionBanner = ({comment}) => {
  return (
    <Box className="p-4 bg-red-100 flex flex-col">
      <Typography className="text-slate-500 text-sm">
        Resubmission Comment
      </Typography>
      <Typography className="text-red-500 text-sm">
        {comment}
      </Typography>
    </Box>
  );
};

export default ResubmissionBanner;
